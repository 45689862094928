'use client';

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import { IdsLink, IdsButton, IdsCheckbox, IdsFieldWrapper, IdsText, IdsButtonGroup } from '@emergn-infinity/ids-react';
import ReCAPTCHA from 'react-google-recaptcha';

import { signInId } from '@/constants/sign-in';
import { Preferences } from '@/interfaces/account-page/preferences';
import { User, UserType } from '@/interfaces/user';
import { AppRoutes } from '@/constants';
import { ApiMethods, ErrorMessage } from '@/constants/api';
import { useApi, useAppDispatch, useAppSelector } from '@/hooks';
import { setUser } from '@/redux/user-slice';
import { AuthToken } from '@/services/auth.service';
import { identifyUser, removeHeap } from '@/services/heap.service';
import { useNotification } from '@/providers/notification-context';
import { deleteTokenFromCookies, saveTokenOnCookies } from '@/server-actions/token';
import { login } from '@/server-actions/auth';
import { deleteChatIdFromCookies } from '@/server-actions/chatbot';
import { createHeadersJson } from '@/services/request.service';

import FormWrapper from '@/components/common/form-wrapper';
import VFQTextField from '@/components/vfq-text-field/vfq-text-field';
import VFQPasswordField from '@/components/vfq-password-field/vfq-password-field';
import TermsOfUse from '@/components/terms-of-use/terms-of-use';

import styles from './main.module.scss';

const auth = new AuthToken();
const headers = new Headers({
  'Content-Type': 'application/json',
});

const handleHeap = (userWithType: User & { type: UserType }, cookiePreferences: Preferences) => {
  if (cookiePreferences?.analytics) {
    identifyUser(userWithType);
  } else {
    removeHeap();
  }
};

type SignInMainProps = {
  cookiePreferences: Preferences;
  newSession: boolean;
};

const handleLogout = async () => {
  const auth = new AuthToken();

  auth.logout(true);

  await deleteChatIdFromCookies();
  await deleteTokenFromCookies();
};

const SignInMain: React.FC<SignInMainProps> = ({ cookiePreferences, newSession }) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');

  const { sendRequest } = useApi();
  const { openErrorNotification } = useNotification();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const router = useRouter();

  const captchaRef = useRef<ReCAPTCHA>();
  const captchaSiteKey = process.env.captchaSiteKey;

  useEffect(() => {
    if (auth.hasValidToken()) {
      if (newSession) {
        handleLogout();
      } else {
        loginUser();
      }
    }
  }, []);

  const staySignedIn = useMemo(() => auth.checkStaySignIn(), []);

  const moveUserForward = async (user: User) => {
    const { isBetaUser, isEmergnUser } = user.type || {};
    const isB2cUser = Object.values(user.type?.isB2cUser || {}).some(Boolean);

    let redirectTo = AppRoutes.KanbanBoard;

    if (user) {
      if (!user.learnerInformation) {
        redirectTo = AppRoutes.SignUpProfessionalDetails;
      } else if (isBetaUser || isEmergnUser || isB2cUser) {
        redirectTo = AppRoutes.ExploreForYou;
      }
    }

    router.push(redirectTo);
  };

  const loginUser = async () => {
    try {
      let userToSignIn = user;

      if (!userToSignIn?.id) {
        const res = await sendRequest(
          'auth/get-user-by-id',
          ApiMethods.POST,
          JSON.stringify({ userId: auth.userId }),
          createHeadersJson(),
          false,
        );

        if (res.statusCode === 401 || !res.data?.user) {
          auth.logout();

          await deleteTokenFromCookies();
          await deleteChatIdFromCookies();

          return;
        }

        userToSignIn = { ...res.data.user, type: res.data.userType };
      }

      moveUserForward(userToSignIn);
    } catch {
      openErrorNotification(ErrorMessage.SOMETHING_WENT_WRONG);
    }
  };

  const verifyCaptcha = async () => {
    if (!auth.checkLoginAttempts) {
      return true;
    }

    const token = await captchaRef.current.executeAsync();
    const res = await sendRequest(
      'auth/check-recaptcha-token',
      ApiMethods.POST,
      JSON.stringify({
        token,
      }),
      headers,
    );

    return res.statusCode === 201;
  };

  const handleFormSubmit = async (values: any): Promise<void> => {
    const captchaIsValid = await verifyCaptcha();

    if (!captchaIsValid) {
      openErrorNotification('reCAPTCHA check has failed');
      return;
    }

    const { email, staySignedIn, password } = values;

    try {
      setLoading(true);

      const res = await login({ email: email.toLocaleLowerCase(), password });

      if (res?.data?.token) {
        const { user, userType, token } = res.data;

        await saveTokenOnCookies(token);

        auth.storeToken(token, staySignedIn);
        auth.resetLoginAttempts();

        if (!user.isActivated) {
          setLoading(false);
          openErrorNotification('Your account has not been activated yet. Please check your email for the activation link.');
          return;
        }

        const userWithType = { ...user, type: userType };

        handleHeap(userWithType, cookiePreferences);
        dispatch(setUser(userWithType));
        moveUserForward(userWithType);
      } else {
        setLoading(false);

        auth.addLoginAttempt();

        if (res.statusCode === 400) {
          openErrorNotification(res.message);
          return;
        }

        if (res.statusCode === 401) {
          setHasError(true);
          return;
        }
      }
    } catch {
      openErrorNotification(ErrorMessage.SOMETHING_WENT_WRONG);
    }
  };

  const errorHandler = () => hasError && setHasError(false);

  const handlePasswordChange = (value: string) => {
    setCurrentPassword(value);
    errorHandler();
  };

  const handleEmailChange = (value: string) => {
    setCurrentEmail(value);
    errorHandler();
  };

  const buttonSelector = 'log-in-button';

  return (
    <FormWrapper onSubmit={handleFormSubmit} className="FormWrapper">
      <div className="FormWrapperHeader">
        <IdsText component="h1" size="sm" alignment="center">
          Sign in
        </IdsText>
      </div>
      <div className="FormWrapperFieldset">
        <VFQTextField
          id={signInId.emailAddressId}
          name="email"
          type="email"
          placeholder="Enter your email address"
          labelName="Email address"
          errorText={hasError ? 'Email or Password is incorrect' : ''}
          changeHandler={handleEmailChange}
          className="is-fullwidth"
          data-elp-testid="sign-in-email-field"
          isRequired
        />
        <VFQPasswordField
          id={signInId.passId}
          name="password"
          placeholder="Enter your password"
          labelName="Password"
          errorText={hasError ? 'Email or Password is incorrect' : ''}
          changeHandler={handlePasswordChange}
          className="is-fullwidth"
          data-elp-testid="sign-in-password-field"
          isRequired
        />
        <IdsFieldWrapper customClasses="is-fullwidth is-stay-signed-in">
          <IdsCheckbox
            label="Stay signed in"
            idValue="staySignedIn"
            defaultChecked={staySignedIn}
            rest={{ name: 'staySignedIn' }}
          />
          <IdsLink variant="brand" size="md" isInline href={AppRoutes.ForgotPassword}>
            Forgot Password?
          </IdsLink>
        </IdsFieldWrapper>
        <ReCAPTCHA className={styles.Captcha} sitekey={captchaSiteKey ?? ''} ref={captchaRef} size="invisible" />
        <IdsFieldWrapper customClasses="FormWrapperActions is-fullwidth">
          <IdsButtonGroup position="center">
            <IdsButton
              id={buttonSelector}
              type="submit"
              fullWidth
              isLoading={loading}
              data-elp-testid={buttonSelector}
              isDisabled={hasError || !currentEmail || !currentPassword}
            >
              Continue
            </IdsButton>
          </IdsButtonGroup>
          <div className={styles.TermsOfUse}>
            <TermsOfUse pageName="SignIn" />
          </div>
        </IdsFieldWrapper>
      </div>
    </FormWrapper>
  );
};

export default SignInMain;
