import React, { BaseSyntheticEvent, ReactNode } from 'react';
import isEmail from 'validator/es/lib/isEmail';

import '@/components/common/form-wrapper.scss';

const getParentProperties = (target: any) => {
  let isRequired = !!target.required;
  let parentLabel = '';

  // for the IDS dropdowns, the required property is on the parentElement
  if (!target.value && target.type === 'text' && !target.required) {
    const isParentRequired = target.parentElement && target.parentElement.getAttribute('is-required') === 'true';
    if (isParentRequired) {
      parentLabel = target.parentElement.dataset.label;
      isRequired = true;
    }
  }
  return { parentLabel, isRequired };
};

const handleInvalidFields = (target: any, obj: any, isRequired: boolean, parentLabel: string) => {
  // required field is empty
  const emptyRequiredField = isRequired && !obj[target.name] && obj[target.name] !== false;
  // required checkbox is left unchecked
  const emptyRequiredCheckbox = target.type === 'checkbox' && target.required && target.checked === false;
  // email field is invalid
  const invalidEmailField = target.name === 'email' && !isEmail(obj[target.name]);

  const handleErrorMessage = (errorMessage: string) => {
    obj['error'][target.id] = errorMessage;

    console.error('error in form', obj['error']);
  };

  if (invalidEmailField) {
    handleErrorMessage('Email is not valid');
  }

  if (emptyRequiredField || emptyRequiredCheckbox) {
    handleErrorMessage(`The ${target.dataset.label || target.ariaLabel || parentLabel} field is required`);
  }
};

type FormWrapperProps = {
  children: ReactNode;
  onSubmit: (values: {}) => void;
  className?: string;
};

const FormWrapper: React.FC<FormWrapperProps> = (props) => {
  const ref = React.createRef<HTMLFormElement>();

  const onSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();

    const obj = {};
    obj['error'] = {};

    for (const target of e.target) {
      if (target.id && target.type !== 'submit' && target.type !== 'button' && target.getAttribute('role') !== 'combobox') {
        obj[target.name] = target.type === 'checkbox' ? target.checked : target.value || target.title;

        const { isRequired, parentLabel } = getParentProperties(target);

        handleInvalidFields(target, obj, isRequired, parentLabel);
      }
    }

    props.onSubmit(obj);
  };

  return (
    <form noValidate ref={ref} onSubmit={onSubmit} className={props.className}>
      {props.children}
    </form>
  );
};

export default FormWrapper;
