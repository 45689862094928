import React from 'react';
import { IdsLink, IdsText } from '@emergn-infinity/ids-react';

import { PRAXIS_MARKETING_WEBSITE } from '@/constants';
import { Policy } from '@/interfaces/policies/policy-enum';

const TermsOfUse: React.FC<{ pageName: string }> = ({ pageName }) => {
  const renderLinkTerms = () => (
    <IdsLink
      href={`${PRAXIS_MARKETING_WEBSITE}/${Policy.TERMS_AND_CONDITIONS}`}
      target="_blank"
      variant="brand"
      size="md"
      isInline
    >
      Terms and Conditions
    </IdsLink>
  );

  const renderLinkPrivacy = () => (
    <IdsLink href={`${PRAXIS_MARKETING_WEBSITE}/${Policy.PRIVACY}`} target="_blank" variant="brand" size="md" isInline>
      Privacy Policy
    </IdsLink>
  );

  const renderTermsOfUseMessageWithLinks = () => {
    if (pageName === 'SignIn') {
      return (
        <>
          By signing in, you agree to the {renderLinkTerms()} and {renderLinkPrivacy()}.
        </>
      );
    }

    if (pageName === 'SignUp') {
      return (
        <>
          By signing up, you agree to the {renderLinkTerms()} and {renderLinkPrivacy()}.
        </>
      );
    }

    return (
      <>
        {renderLinkTerms()}, {renderLinkPrivacy()}
      </>
    );
  };

  return (
    <IdsText size="md" alignment="center">
      {renderTermsOfUseMessageWithLinks()}
    </IdsText>
  );
};

export default TermsOfUse;
