import React from 'react';
import { IdsFieldWrapper, IdsTextInput } from '@emergn-infinity/ids-react';

import { VFQTextFieldProps } from './interfaces';

const VFQTextField: React.FC<VFQTextFieldProps> = ({
  labelName,
  id,
  name = '',
  errorText,
  isRequired,
  isReadonly = false,
  onBlur,
  onFocus,
  className,
  dataTestId,
  ...props
}) => {
  return (
    <IdsFieldWrapper
      wrapperLabel={labelName}
      helperInvalidText={errorText}
      isInvalid={!!errorText}
      isRequired={isRequired}
      isReadonly={isReadonly}
      customClasses={className}
    >
      <IdsTextInput
        {...props}
        themeName="vfq"
        rest={{
          id,
          name,
          onBlur,
          onFocus,
          maxLength: '255',
          required: isRequired,
          ariaLabel: labelName,
          'data-elp-testid': dataTestId,
        }}
      />
    </IdsFieldWrapper>
  );
};

export default VFQTextField;
